.submitButton {
    background-color: #1a8700;
    border: none;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    height: 100%;
    margin-top: 20px;
    padding: 1rem;
}

.submitButton:hover {
    background-color: #146500 !important;
    color: #fff !important;
}

.form {
    width: 100%;
    display: grid;
    gap: 1rem
}

.formGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem
}


@media (max-width: 1023px) {
    .formGrid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

}

.container {
    display: grid;
    gap: 0.25rem;
}

.title {
    font-size: 1.125rem;
    /* text-lg */
    font-weight: bold;
}

.instruction {
    font-size: 0.875rem;
    /* text-sm */
    font-weight: normal;
}

.flexContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    flex: 1;
}

.labelLight {
    font-weight: 600;
    color: black;
}

.labelDark {
    font-weight: 600;
    color: white;
}

.uploadButton {
    box-shadow: none;
    font-weight: bold;
    color: black;
    background-color: white;
}