/* Contêiner principal */
.errorContainer {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    max-width: 1360px;
    margin: 0 auto;
}

/* Layouts para dispositivos */
.mobileLayout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
}

.desktopLayout {
    display: grid;
    grid-template-columns: 5fr 5fr;
    gap: 1rem;
}

/* Estilos da seção de logo e título */
.logoSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

.desktopSpacing {
    align-items: baseline;
    justify-content: center;
    padding-right: 30px;
}

.name {
    font-size: 1.45rem;
    font-weight: 700;
    margin: 0;
}

@media (max-width: 1023px) {
    .name {
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0;
    }
}

.nameDark {
    color: #fff;
    /* Cor para nome no tema escuro */
}

.nameLight {
    color: #000;
    /* Cor para nome no tema claro */
}

.logo {
    width: 10rem;
    max-width: 10rem;
    height: 2rem;
    object-fit: contain;
    cursor: pointer;
}

.title {
    font-size: 2rem;
    max-width: 700px;
    text-align: left;
    font-weight: 700;
    line-height: 54px;
}

/* Seção de estatísticas */
.statsSection {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.statsRow {
    display: flex;
    gap: 1rem;
}

.card {
    text-align: center;
}

.statNumber {
    font-size: 1.5rem;
    font-weight: 700;
}

.statText {
    font-size: 0.625rem;
}

.stars {
    color: #EC942C;
    /* Amarelo para as estrelas */
}

.form {
    width: 100%;
    display: grid;
    gap: 1rem;
}

/* Seção de mensagem de erro */
.messageSection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardDark {
    display: flex;
    background-color: #27272A;
    color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: start;
    min-height: 700px;
    align-items: center;
    width: 100%;
}

.cardDarkPeq {
    background-color: #27272A;
    color: white;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid #FFFFFF1A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardLight {
    display: flex;
    background-color: #ffffff;
    color: #000;
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: start;
    min-height: 700px;
    align-items: center;
    width: 100%;
}

.cardLightPeq {
    background-color: #ffffff;
    color: white;
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid #FFFFFF1A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.errorHeader {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
}

.errorCode {
    font-size: 3.688rem;
    font-weight: 700;
}

.errorMessage {
    font-size: 1.75rem;
    font-weight: 700;
}

.errorDescription {
    margin: 1rem 0;
    font-size: 1.25rem;
    color: #B5B5B5;
}

.starsFlex{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.websiteLink {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border: 3px solid #39393B;
    border-radius: 10px;
    color: inherit;
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-top: 30px;
}

.websiteLink:hover {
    background-color: #39393B;
    color: white;
}


@media (max-width: 1023px) {
    .logoSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
    .title {
        font-size: 1.7rem;
        /* max-width: 300px; */
        text-align: left;
        font-weight: 700;
        line-height: 30px;
    }
    .cardDark {
        padding: 1rem;
        min-height: auto;
    }
    .errorContainer {
        padding: 1rem;
    }
}


@media (min-width: 1024px) {
    .errorContainer {
        padding: 1rem;
    }
}